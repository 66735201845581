import * as actionTypes from './actions';
import config from './../config';

const initialState = {
  isOpen: [], //for active default menu
  isTrigger: [], //for active default menu, set blank for horizontal
  ...config,
  isFullScreen: false, // static can't change
  snackbarType: 'success',
  user: {
    token: null,
    name: null,
    email: null,
  }
};

const reducer = (state = initialState, action) => {
  let trigger = [];
  let open = [];

  switch (action.type) {
    case actionTypes.USER_SET:
      return {
        ...state,
        user: action.user
      };
    case actionTypes.SNACKBAR_SHOW:
      return {
        ...state,
        showSnackbar: true,
        snackbarType: action.snackbarType,
        snackbarMessage: action.snackbarMessage,
      };
    case actionTypes.SNACKBAR_LEAVE:
      return {
        ...state,
        showSnackbar: false,
      };
    case actionTypes.WORK_ITEM_CREATE_SHOW:
      return {
        ...state,
        showCreateWorkItem: true,
      };
    case actionTypes.WORK_ITEM_CREATE_LEAVE:
      return {
        ...state,
        showCreateWorkItem: false,
      };
    case actionTypes.COLLAPSE_MENU:
      return {
        ...state,
        collapseMenu: !state.collapseMenu
      };
    case actionTypes.COLLAPSE_TOGGLE:
      if (action.menu.type === 'sub') {
        open = state.isOpen;
        trigger = state.isTrigger;

        const triggerIndex = trigger.indexOf(action.menu.id);
        if (triggerIndex > -1) {
          open = open.filter(item => item !== action.menu.id);
          trigger = trigger.filter(item => item !== action.menu.id);
        }

        if (triggerIndex === -1) {
          open = [...open, action.menu.id];
          trigger = [...trigger, action.menu.id];
        }
      } else {
        open = state.isOpen;
        const triggerIndex = (state.isTrigger).indexOf(action.menu.id);
        trigger = (triggerIndex === -1) ? [action.menu.id] : [];
        open = (triggerIndex === -1) ? [action.menu.id] : [];
      }

      return {
        ...state,
        isOpen: open,
        isTrigger: trigger
      };
    case actionTypes.NAV_CONTENT_LEAVE:
      return {
        ...state,
        isOpen: open,
        isTrigger: trigger,
      };
    case actionTypes.NAV_COLLAPSE_LEAVE:
      if (action.menu.type === 'sub') {
        open = state.isOpen;
        trigger = state.isTrigger;

        const triggerIndex = trigger.indexOf(action.menu.id);
        if (triggerIndex > -1) {
          open = open.filter(item => item !== action.menu.id);
          trigger = trigger.filter(item => item !== action.menu.id);
        }
        return {
          ...state,
          isOpen: open,
          isTrigger: trigger,
        };
      }
      return {...state};
    case actionTypes.FULL_SCREEN :
      return {
        ...state,
        isFullScreen: !state.isFullScreen
      };
    case actionTypes.FULL_SCREEN_EXIT:
      return {
        ...state,
        isFullScreen: false
      };
    case actionTypes.CHANGE_LAYOUT:
      return {
        ...state,
        layout: action.layout
      };
    default:
      return state;
  }
};

export default reducer;
